import React from 'react'
import "../App.css";
function Cont1() {
  return (
    <section id='Home'>
   <div className='cont1'>
   <div className="mainHead">
   <h1>Nitika Sustainable Forestry Limited</h1>
   </div>
    </div>
   </section>
  )
}

export default Cont1
