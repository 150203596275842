import React from 'react'
import FooterLogo from '../Images/logo.png'
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
function Footer() {
  return (
    <section id="Contact">
            <div className='footer'>
      <div className="footerLogo">
      <img src={FooterLogo} alt="" />
      <h3>Nitika Sustainable Forestry Limited</h3>
      </div>
      <div className="footerContext">
      <h2>Contact Us</h2>
      <br />
      <p><FaPhoneAlt/> : +64223055088</p>
      <p><MdOutlineMail/> : admin@nsforestryltd.co.nz</p>
      </div>
    </div>
    </section>
  )
}

export default Footer
