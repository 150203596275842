import React from 'react'

function Products({product}) {
    return (
        <div className="product-card">
          <img src={product.image} alt={product.name} className="product-image" />
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
        </div>
    )
}

export default Products
