import React from 'react'
import "../App.css"
import img from "../Images/logo.png"
function Navbar() {
    return (
        <nav className="navbar">
          <div className="logo">
            <a href="#Home"><img src={img} alt="Logo"/></a>
          </div>
          <div className="links">
            <ul className="nav-links">
                <li><a href="#Vision">Vision</a></li>
                <li><a href="#About">About</a></li>
                <li><a href="#Products">Products</a></li>
                <li><a href="#Contact">Contact Us</a></li>
            </ul>
          </div>
        </nav>
      );
}

export default Navbar
