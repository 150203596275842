import A from '../Images/A.jpeg';
import KI from '../Images/KI.jpeg'
import K from '../Images/K.jpeg';
import KIS from '../Images/KIS.jpeg';
import product1 from '../Images/Product1.png'
import product4 from '../Images/Product4.jpeg'
import product5 from '../Images/Product5.jpeg'

const productsData = [
    {
      id: 1,
      name: "Grade-P",
      description: "Grade P wood is a high-quality, premium grade timber known for its excellent durability, strength, and smooth finish. It is commonly used in fine woodworking, furniture making, and construction projects that require precision and a high level of craftsmanship",
      price: 29.99,
      image: product1,
    },
    {
      id: 2,
      name: "Grade-A",
      description: "Grade A wood is high-quality lumber, free from defects like knots, splits, or warps. It has a smooth finish, consistent grain, and is ideal for fine furniture, flooring, or cabinetry. Its durability and aesthetic appeal make it perfect for premium projects.",
      price: 19.99,
      image: A,
    },
    {
      id: 3,
      name: "Grade-K",
      description: "Grade K wood is an affordable, economy-grade option ideal for non-structural projects like fencing or basic construction. Priced competitively, it appeals to contractors, DIY enthusiasts, and businesses looking for cost-effective materials. Available in bulk with discounts for larger orders",
      price: 29.99,
      image: K,
    },
    {
      id: 4,
      name: "Grade-KI",
      description: "Grade KI wood is top-tier, kiln-dried lumber known for its stability and reduced moisture content. This makes it resistant to warping, shrinking, or swelling, ensuring long-lasting durability. Ideal for precise woodworking, it offers a smooth finish and high-quality performance in construction, furniture, and interior projects.",
      price: 39.99,
      image: KI,
    },
    {
      id: 5,
      name: "Grade-KIS",
      description: "Grade KIS wood is stress-relieved lumber, offering superior stability and strength. It resists warping and splitting, making it ideal for structural applications and precise woodworking. With its high durability and smooth finish, it’s perfect for high-performance construction and premium furniture projects.",
      price: 24.99,
      image: KIS,
    },

  ];
  
  export default productsData;