import React from 'react'

function Cont4() {
  return (
    <section id='About'>
        <div className='conatiner4'>
        <div className="container41">
        <div className="container41Text">
        <h1>About Us</h1>
        <p>At N.S Forestry limited, we export premium-quality radiata pine logs from New Zealand to global markets. Committed to sustainability and excellence, we offer a variety of grades tailored to construction, furniture-making, and industrial needs. As a leading supplier, we provide top-grade logs known for its strength, versatility, and durability. Backed by reliable service and long-term partnerships, N.S forestry  limited is your trusted source for high-quality Logs exports..</p>
        </div>
        </div>
        </div>
    </section>
  )
}

export default Cont4
