import './App.css';
import Navbar from './Components/Navbar'
import Cont1 from './Components/Cont1'
import Cont2 from './Components/Cont2'
import Cont3 from './Components/Cont3'
import Footer from './Components/Footer.js'
import Cont4 from './Components/Cont4.js';
import Cont5 from './Components/Cont5.js';
function App() {
  return (
    <div className="App">
    <Navbar/>
    <Cont1/>
    <Cont2/>
    <Cont4/>
    <Cont5/>
    <Cont3/>
    <Footer/>
    </div>
  );
}

export default App;
