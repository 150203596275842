import React from 'react'

function Cont5() {
  return (
<section id='Services'>
<div className='container5 container2'>
<div className='container52 container22'>
At Nitika Sustainable Forestry Limited, we proudly offer an extensive selection of wood products, expertly categorized by grade to meet the unique needs of your projects. Our NZ Radiata Pine is a versatile, sustainably grown softwood, renowned for its lightweight strength and durability. With multiple grades available, you’ll find the perfect option to achieve the ideal balance of strength and aesthetic appeal, ensuring your creations stand out with quality and sustainability.</div>
<div className='container51 container21'>
<h1>Our Services</h1>
</div>
</div>
</section>
  )
}

export default Cont5
