import React from 'react'

function Cont2() {
  return (
<section id='Vision'>
<div className='container2'>
<div className='container21'>
<h1>Our Vision</h1>
</div>
<div className='container22'>
Our vision is to become a leading global supplier, delivering exceptional products and services that set new standards in quality, driven by growth, innovation, and creating opportunities for our company and its people. We are committed to delighting clients worldwide with superior products that offer unmatched value, while continuously refining our processes and protecting the planet’s green environment to ensure a sustainable future for generations to come.</div>
</div>
</section>
  )
}

export default Cont2
