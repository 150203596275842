import React from 'react'
import Products from './Products'
import productsData from './ProductsData'; 
function Cont3() {
  return (
   <section id='Products'>
   <div className='cont3'>
   <h1>Products</h1>
   <div className="product-list">
     {productsData.map((product) => (
       <Products key={product.id} product={product} />
     ))}
   </div>
    </div>
   </section>
  )
}

export default Cont3
